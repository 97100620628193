
import {regen} from "../utils/axios";
import { getUserId } from "../utils/jwt";


export const updateGamingBarStatus = async (data) => {
  const userId = getUserId();
  const URL = `/users/${userId}/updateGamingBarStatus`;
  console.log('userId', URL);
  if (!userId) {
    return;
  }
  const response = await regen.put(URL, data);
  return response.data;
};
