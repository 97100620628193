export const GAMIFICATION_STEPS = [
  {
    title: "Login",
    text: "Provide the best treatments for your clients using high-quality ingredients, world-class eduction and a personalized shopping experience.",
    icon: "gm_monogram_icon",
    couponMessage: "Welcome code for 20% off and free shipping at checkout",
    couponProperty: "welcomeCoupon",
  },
  {
    title: "First Pro purchase",
    text: "Shop now and stock up on Back Bar, Retail, Travel and Trial size products for your clinic.",
    icon: "shopping_cart_icon",
    property: "hasProPurchase",
    action: "shop now",
    route: "/products",
    couponMessage: "Promo code for free shipping at checkout",
    couponProperty: "proCouponCode",
  },
  {
    title: "Set up Retail Store",
    text: "Maximize your earning potential! Offer your clients a personalized online shopping experience and earn 40% revenue share for every purchase.",
    icon: "regen_retail_icon",
    property: "hasRetailStore",
    action: "set up now",
    newTab: true,
    route: "https://www.pdffiller.com/en/link_to_fill/1414403818.htm",
    couponMessage:
      "Promo code for free sample at checkout with the purchase of any other item",
    couponProperty: "retailCouponCode",
  },
  {
    title: "First Retail Client",
    text: "Add new clients under the MY STORE section of your pro portal or start sharing your REGEN Retail link with your clients and followers on social media or embed it on your business website. You can even download a QR Code to display in your practice.",
    icon: "client_icon",
    property: "hasRetailClient",
    // gift: true,
    // giftMessage: "reward points of 200",
    action: "add clients now",
    route: "/b2b/my-store/clients",
  },
  {
    title: "First Retail Purchase",
    text: "Share your REGEN Retail link with your clients and followers on social media or embed the link on your business website. You can even download a custom QR Code to display in your clinic.",
    icon: "shopping-bag",
    property: "hasRetailPurchase",
    action: "Learn more",
    action2: "more help",
    route: "/regen-resources",
    route2: "/resources",
  },
  {
    title: "First Recommendation Bundle",
    text: "Provide your clients with customized product recommendations by creating a bundle and sharing the link.",
    icon: "bundle_icon",
    property: "hasRecommendationBundle",
    action: "create bundle",
    route: "/b2b/my-store/my-recommendations",
  },
];

export const GAMIFICATION_STATUS = {
  COMPLETED: "completed",
  ACTIVE: "active",
  EXISTING: "existing",
  PENDING: "pending",
};
