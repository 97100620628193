import { getUser } from "../../utils/jwt";
import { GAMIFICATION_STEPS } from "./Constants";

export const generateGamificationSteps = () => {
  const user = getUser();
  
  if (!user || user.accountType !== "pro") {
    return;
  }
  const totalSteps = GAMIFICATION_STEPS.length - 1;

  const newSteps = [];
  if (user) {
    newSteps.push({ ...GAMIFICATION_STEPS[0], completed: true });
  }
  let firstIndex = 1;
  let lastIndex = GAMIFICATION_STEPS.length - 1;

  for (let i = 1; i < GAMIFICATION_STEPS.length; i++) {
    if (user[GAMIFICATION_STEPS[i].property]) {
      newSteps[firstIndex] = { ...GAMIFICATION_STEPS[i], completed: true };
      firstIndex++;
    } else {
      for (let j = lastIndex; j < totalSteps; j++) {
        newSteps[j] = newSteps[j + 1];
      }
      newSteps[totalSteps] = GAMIFICATION_STEPS[i];
      lastIndex--;
    }
  }
  newSteps[newSteps.length - 1].couponMessage =
    "Complete all steps to earn 20,000 reward points";

  return { steps: newSteps, activeStep: firstIndex };
};
