
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import Box from "@mui/material/Box";
import { StyledBodyRegular } from "../styled"
import CloseIcon from '@mui/icons-material/Close';
import css from "../styles/AnimatedRewardBox.module.css"
import confettiAnimation from '../utils/json/confetti.json';
import classNames from "classnames";


const AnimatedRewardBox = ({ show = false }) => {

  const [showReward, setShowReward] = useState(show);
  const [hideReward, setHideReward] = useState(false);

  const handleClose = () => {
    setHideReward(true);
    setTimeout(() => {
      setShowReward(false);
      //Any Api call to update the reward
    }, 500);
  }

  useEffect(() => {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [])

  if (!showReward) return null;

  return (
    <>
      <Lottie
        animationData={confettiAnimation}
        loop={2}
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 2
        }}
        onComplete={handleClose}
      />
      <Box className={classNames(css.rewardContainer, hideReward ? css.hide : "")}>
        <Box className={css.rewardBox}>
          <CloseIcon onClick={handleClose} className={css.closeIcon} />
          <StyledBodyRegular>
            🎉 You earned 20,000 Points!
          </StyledBodyRegular>
        </Box>
      </Box>
    </>
  );
};

export default AnimatedRewardBox;