
import { useMutation, useQueryClient } from "react-query";
import {
  updateGamingBarStatus
} from "../services";

export const useUpdateGamingBarStatus = (onError, onSuccess) => {
  const queryClient = useQueryClient();


  return useMutation(updateGamingBarStatus, {
    onSuccess: () => {
      onSuccess && onSuccess();
      queryClient.invalidateQueries("userById");
      queryClient.invalidateQueries("regenRewards");
    },
    onError: (error) => {
      onError && onError(error?.message);
    },
  });
};